<template>
	<div>
		<template>
      <v-data-table
        :headers="tables.trainingPlans.headers"
				:items="trainingPlans.filter(tP => tables.trainingPlans.filters.ageGroup==-1||tP.recommendedAgeGroups.filter(rAG => rAG.id==tables.trainingPlans.filters.ageGroup).length>0)"
        :search="tables.trainingPlans.search"
      >
	      <template v-slot:top>
					<v-row>
						<v-col cols="12" sm="11">
			        <v-text-field
			          v-model="tables.trainingPlans.search"
			          label="Keresés"
			          prepend-inner-icon="fa-search"
			          class="mx-4"
								rounded
								outlined
			        />
						</v-col>

						<!--<v-col cols="12" sm="2">
							<v-select
								:items="[{id: -1, name: 'Mindegyik'}].concat(ageGroups)"
								item-text="name"
								item-value="id"
								label="Korcsoport"
								v-model="tables.trainingPlans.filters.ageGroup"
								rounded
								outlined
								prepend-inner-icon="fa-child"
							/>
						</v-col>-->
						<v-col cols="12" sm="1">
							<v-tooltip top>
								Hozzáadás
								<template v-slot:activator="{on, attrs}">
									<v-btn fab color="success" v-on="on" v-bind="attrs" @click="dialog.show=true"><v-icon>fa-plus</v-icon></v-btn>
								</template>
							</v-tooltip>
						</v-col>
					</v-row>
	      </template>

				<template v-slot:item.competition="{item}">
					<v-icon :color="item.competition? 'success' : 'red accent-4'">{{item.competition? "fa-check" : "fa-times"}}</v-icon>
				</template>
				<template v-slot:item.ageGroups="{item}">
	      </template>

				<template v-slot:item.view="{item}">
					<v-btn icon :to="{name: 'trainingPlan', params: {id: item.id}}"><v-icon>fa-eye</v-icon></v-btn>
				</template>

      </v-data-table>
		  <v-row justify="center">
		    <v-dialog
		      v-model="dialog.show"
		      persistent
		      max-width="600px"
		    >

		      <v-card>
		        <v-card-title>
		          <span class="headline">Új edzésterv</span>
		        </v-card-title>
		        <v-card-text>
		          <v-container>
								<v-text-field
									prepend-inner-icon="fa-clipboard-list"
									label="Edzésterv"
									v-model="dialog.trainingPlan.name"
									rounded
									outlined
								/>
								<v-textarea
									prepend-inner-icon="fa-info-circle"
									label="Leírás"
									v-model="dialog.trainingPlan.description"
									rounded
									outlined
								/>
								<v-checkbox
									label="Versenyterv"
									v-model="dialog.trainingPlan.competition"
								/>
                <v-checkbox
                  v-if="$store.getters.userRole>=roles.nationalCoordinator"
									label="Publikus"
									v-model="dialog.trainingPlan.public"
								/>
		          </v-container>
		        </v-card-text>
		        <v-card-actions>
		          <v-spacer></v-spacer>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="dialog.show = false"
		          >
		            Mégsem
		          </v-btn>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="create()"
								v-if="dialog.trainingPlan.name&&dialog.trainingPlan.description"
		          >
		            Hozzáadás
		          </v-btn>
		        </v-card-actions>
		      </v-card>
	    </v-dialog>
	  </v-row>

	</template>
	</div>
</template>

<script>
	import router from '../router';
	export default {
		data: function(){
			return {
        trainingPlans: [],
				ageGroups: [],
        tables: {
          trainingPlans: {
            search: '',
						filters: {
							ageGroup: -1
						},
            headers: [
              {
                text: "Edzésterv",
                align: "center",
                sortable: true,
                filterable: true,
                value: "name"
              },
              {
                text: "Leírás",
                align: "center",
                sortable: false,
                filterable: true,
                value: "description"
              },
              {
                text: "Verseny",
                align: "center",
                sortable: false,
                filterable: true,
                value: "competition"
              },
							/*{
								text: "Korcsoportok",
								align: "center",
								sortable: false,
								filterable: false,
								value: "ageGroups"
							},*/
							{
								text: "Megtekintés",
								align: "center",
								sortable: false,
								filterable: false,
								value: "view"
							}
            ]
          }
        },
        dialog: {
          show: false,
					trainingPlan: {
						name: '',
						description: '',
						competition: false,
            public: false
					}
        }
      }
		},
		computed: {

		},
		watch: {

		},
		methods: {
			create: function() {
				this.axios({url: "trainingplan", method: "POST", data: this.dialog.trainingPlan}).then((response) => {
					if(response.data.success) {
						this.trainingPlans.push({...this.dialog.trainingPlan, id: response.data.data});
						this.dialog.show=false;
						this.dialog.trainingPlan = { name: '', description: '', competition: false, public: false }
						this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént.')
					}
				})
			}
		},
		mounted(){
			this.axios({url: "trainingplan", method: "GET"}).then((response) => {
        if(response.data.success) {
          this.trainingPlans=response.data.data.trainingPlans
					this.ageGroups = response.data.data.ageGroups
        }
			})
		}
	}
</script>
