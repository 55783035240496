var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-data-table',{attrs:{"headers":_vm.tables.trainingPlans.headers,"items":_vm.trainingPlans.filter(function (tP) { return _vm.tables.trainingPlans.filters.ageGroup==-1||tP.recommendedAgeGroups.filter(function (rAG) { return rAG.id==_vm.tables.trainingPlans.filters.ageGroup; }).length>0; }),"search":_vm.tables.trainingPlans.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"11"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Keresés","prepend-inner-icon":"fa-search","rounded":"","outlined":""},model:{value:(_vm.tables.trainingPlans.search),callback:function ($$v) {_vm.$set(_vm.tables.trainingPlans, "search", $$v)},expression:"tables.trainingPlans.search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"success"},on:{"click":function($event){_vm.dialog.show=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-plus")])],1)]}}])},[_vm._v(" Hozzáadás ")])],1)],1)]},proxy:true},{key:"item.competition",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.competition? 'success' : 'red accent-4'}},[_vm._v(_vm._s(item.competition? "fa-check" : "fa-times"))])]}},{key:"item.ageGroups",fn:function(ref){
var item = ref.item;
return undefined}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{name: 'trainingPlan', params: {id: item.id}}}},[_c('v-icon',[_vm._v("fa-eye")])],1)]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Új edzésterv")])]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"prepend-inner-icon":"fa-clipboard-list","label":"Edzésterv","rounded":"","outlined":""},model:{value:(_vm.dialog.trainingPlan.name),callback:function ($$v) {_vm.$set(_vm.dialog.trainingPlan, "name", $$v)},expression:"dialog.trainingPlan.name"}}),_c('v-textarea',{attrs:{"prepend-inner-icon":"fa-info-circle","label":"Leírás","rounded":"","outlined":""},model:{value:(_vm.dialog.trainingPlan.description),callback:function ($$v) {_vm.$set(_vm.dialog.trainingPlan, "description", $$v)},expression:"dialog.trainingPlan.description"}}),_c('v-checkbox',{attrs:{"label":"Versenyterv"},model:{value:(_vm.dialog.trainingPlan.competition),callback:function ($$v) {_vm.$set(_vm.dialog.trainingPlan, "competition", $$v)},expression:"dialog.trainingPlan.competition"}}),(_vm.$store.getters.userRole>=_vm.roles.nationalCoordinator)?_c('v-checkbox',{attrs:{"label":"Publikus"},model:{value:(_vm.dialog.trainingPlan.public),callback:function ($$v) {_vm.$set(_vm.dialog.trainingPlan, "public", $$v)},expression:"dialog.trainingPlan.public"}}):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog.show = false}}},[_vm._v(" Mégsem ")]),(_vm.dialog.trainingPlan.name&&_vm.dialog.trainingPlan.description)?_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.create()}}},[_vm._v(" Hozzáadás ")]):_vm._e()],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }